import { Card, Col, Container, Row, Button } from "react-bootstrap";
import PoolInfo from "../PoolInfo/PoolInfo";
import CURELogo from "../../assets/CURE-v2-200x200.png";
import CURELPLogo from "../../assets/CURE+BNB-200x200.png";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import { StakingLib } from "../../lib/StakingLib";
import Web3 from "web3";

var walletInterval = null;
var rewardsInterval = null;

export default function PageContent() {
  const [amountToHarvest, setAmountToHarvest] = useState(Web3.utils.toBN(0));
  const [amountOnWallet, setAmountOnWallet] = useState("0");
  const [accruedCURE, setAccruedCURE] = useState(Web3.utils.toBN(0));
  const [poolUserRewards, setPoolUserRewards] = useState([]);

  const { isConnected, isDisconnected, account, provider, chainId } =
    useContext(AppContext);

  useEffect(() => {
    if (isConnected && account != null) {
      clearInterval(walletInterval);
      clearInterval(rewardsInterval);
      setAmountOnWallet("0");
      loadUserInfo();
    } else if (isDisconnected) {
      setAmountOnWallet("0");
    }
  }, [isConnected, account]);

  const loadUserInfo = async () => {
    loadWalletBalance(account);
    loadRewardsInfo(account);
    walletInterval = setInterval(loadWalletBalance, 5000, account);
    rewardsInterval = setInterval(loadRewardsInfo, 10000, account);
  };

  const loadWalletBalance = async (account) => {
    if (isConnected && account != null) {
      try {
        setAmountOnWallet(await StakingLib.getUserBalance(account));
      } catch {}
    }
  };

  const loadRewardsInfo = async (account) => {
    if (isConnected && account != null) {
      try {
        let total = Web3.utils.toBN(0);
        let available = Web3.utils.toBN(0);
        const poolsLength = await StakingLib.getPoolsLength();
        // let stakes = [];
        let rewards = [];
        for (let pId = 0; pId < poolsLength; pId++) {
          const pendingRewards = Web3.utils.toBN(
            await StakingLib.getPendingRewards(pId, account)
          );
          rewards.push(pendingRewards);
          const lockExpirationTime =
            parseFloat(await StakingLib.getLockExpirationTime(pId, account)) *
            1000;
          const currentTime = new Date().getTime();
          if (currentTime > lockExpirationTime) {
            available = available.add(pendingRewards);
          }
          total = total.add(pendingRewards);
        }
        setPoolUserRewards(rewards);
        setAmountToHarvest(available);
        setAccruedCURE(total);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onHarvestAllClick = async (e) => {
    StakingLib.harvestAll(
      provider,
      account,
      (hash) => {},
      (confirmationNumber, receipt) => {},
      (error, receipt) => {}
    );
  };

  return (
    <main className="text-white">
      <Container>
        <h1 className="mb-5">
          <strong>CURE</strong> Staking Platform
        </h1>
        {isConnected ? (
          <Card id="userStatus" className="mb-5">
            <Card.Body>
              <Row>
                <Col
                  sm="12"
                  md="12"
                  lg="3"
                  style={{ alignItems: "center", display: "flex" }}
                  className="mt-1 mb-1"
                >
                  <img src={CURELogo} alt="CURE Token Logo" width="48" />
                  <span className="ms-2">
                    Accrued CURE <br />
                    <span className="number">
                      {parseFloat(
                        Web3.utils.fromWei(accruedCURE)
                      ).toLocaleString()}
                    </span>
                  </span>
                </Col>
                <Col
                  sm="12"
                  md="12"
                  lg="3"
                  style={{ alignItems: "center", display: "flex" }}
                  className="mt-1 mb-1"
                >
                  <img src={CURELogo} alt="CURE Token Logo" width="48" />
                  <span className="ms-2">
                    CURE to Harvest <br />
                    <span
                      className="number"
                      title={Web3.utils.fromWei(amountToHarvest)}
                    >
                      {parseFloat(Web3.utils.fromWei(amountToHarvest))
                        .toFixed(3)
                        .toLocaleString()}
                    </span>
                  </span>
                </Col>
                <Col
                  sm="12"
                  md="12"
                  lg="3"
                  style={{ alignItems: "center", display: "flex" }}
                  className="mt-1 mb-1"
                >
                  <i className="bi-wallet2" style={{ fontSize: "48px" }}></i>
                  <span className="ms-2">
                    CURE in Wallet <br />
                    <span className="number">
                      {parseFloat(
                        Web3.utils.fromWei(amountOnWallet, "nano")
                      ).toLocaleString()}
                    </span>
                  </span>
                </Col>
                <Col
                  sm="12"
                  md="12"
                  lg="3"
                  style={{ alignItems: "center", display: "flex" }}
                  className="mt-1 mb-1"
                >
                  <Button
                    className="ms-auto me-2"
                    variant="primary"
                    onClick={onHarvestAllClick}
                    disabled={amountToHarvest.eqn(0)}
                  >
                    Harvest All
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : null}
        <PoolInfo
          rate="3.0% APY"
          lock="30 Days"
          symbol="CURE"
          logo={CURELogo}
          rewardLogo={CURELogo}
          pId={0}
          isDepositMode={true}
          amountOnWallet={amountOnWallet}
          userRewards={poolUserRewards[0]}
        />
        <PoolInfo
          rate="9.5% APY"
          lock="90 days"
          logo={CURELogo}
          rewardLogo={CURELogo}
          symbol="CURE"
          pId={1}
          isDepositMode={true}
          isExpired={false}
          amountOnWallet={amountOnWallet}
          userRewards={poolUserRewards[1]}
        />
        <PoolInfo
          rate="40.0% APY"
          lock="1 Year"
          logo={CURELogo}
          rewardLogo={CURELogo}
          symbol="CURE"
          pId={2}
          isDepositMode={true}
          isExpired={false}
          amountOnWallet={amountOnWallet}
          userRewards={poolUserRewards[2]}
        />
        <h2 className="mt-5">Closed pools</h2>
        <p>There are no closed pools.</p>
      </Container>
    </main>
  );
}
