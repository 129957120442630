import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import AppContext from "../../AppContext";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import toastr from "toastr";
import { defaultNetwork, networkConfigs } from "../../lib/StakingLib";

export default function ConnectButton() {
  const [buttonText, setButtonText] = useState("");
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [isInvalidChain, setIsInvalidChain] = useState(false);
  const {
    isConnected,
    setIsConnected,
    isDisconnected,
    setIsDisconnected,
    account,
    setAccount,
    provider,
    setProvider,
    chainId,
    setChainId,
  } = useContext(AppContext);

  useEffect(() => {
    if (provider !== null) {
      setIsConnected(true);
      setIsDisconnected(false);
      loadAccount();
    }
  }, [provider]);

  useEffect(() => {
    if (chainId != null) {
      if (chainId != defaultNetwork) {
        setIsInvalidChain(true);
        switchNetwork();
      }
      else {
        setIsInvalidChain(false);
      }
    }
  }, [chainId]);

  const loadAccount = async () => {
    const web3 = new Web3(provider);
    const acct = (await web3.eth.getAccounts())[0];
    const currentChainId = await provider.request({ method: "eth_chainId" });
    setAccount(acct);
    setChainId(currentChainId);
  };

  useEffect(() => {
    if (isConnected && account != null)
      setButtonText("Connected: " + maskedAccount());
    else setButtonText("Connect Wallet");
  }, [isConnected, account]);

  const maskedAccount = () =>
    account != null ? account.substr(0, 4) + "****" + account.substr(account.length - 4) : "";

  const doConnect = async () => {
    if (!isConnected) {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            //infuraId: "9aa3d95b3bc440fa88ea12eaa4456161", // required
            rpc: {
              1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
              56: "https://bsc-dataseed.binance.org",
              97: "https://data-seed-prebsc-1-s1.binance.org:8545",
            },
            qrcodeModalOptions: {
              mobileLinks: [
                "trust",
                "metamask",
                "safepal",
                "ledger",
                "atomic",
                "rainbow",
                "argent",
                "imtoken",
                "pillar",
              ],
            },
          },
        },
      };

      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        providerOptions, // required
      });

      let _provider = await web3Modal.connect();

      // Subscribe to accounts change
      _provider.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          setIsConnected(true);
          setIsDisconnected(false);
        } else {
          setAccount(null);
          setIsConnected(false);
          setIsDisconnected(true);
        }
      });

      // Subscribe to chainId change
      _provider.on("chainChanged", (chainId) => {
        setChainId(chainId);
      });

      // Subscribe to provider connection
      _provider.on("connect", (info) => {
        setIsConnected(true);
        setIsDisconnected(false);
        console.log(info);
      });

      // Subscribe to provider disconnection
      _provider.on("disconnect", (error) => {
        setIsDisconnected(true);
        setIsConnected(false);
        console.log(error);
      });

      setProvider(_provider);
    } else {
      setShowWalletModal(true);
    }
    // if (!isConnected) {
    //   // do web3mocal stuff
    //   setIsConnected(true);
    //   setAccount("0xf047100Ec4D3961c7CEa592F67e008dC7c50017b");
    // } else {
    //   // show mwallet modal
    //   setAccount("0xf047100Ec4D3961c7CEa592F67e008dC7c50017b");
    // }
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(account);
    toastr.info("Address copied successfully to clipboard!", "Copy Address");
  };

  const viewOnExplorer = () => {
    window.open(
      networkConfigs[defaultNetwork].blockExplorerUrls[0] +
        "/address/" +
        account
    );
  };

  const switchNetwork = async (e) => {
    try {
      provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: defaultNetwork }],
      });
    } catch (err) {
      if (err.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [networkConfigs[defaultNetwork]],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
    }
  };

  return (
    <>
      <Button variant="primary" onClick={doConnect}>
        {buttonText}
      </Button>
      {isConnected ? (
        <Modal
          id="walletModal"
          show={showWalletModal}
          onHide={() => setShowWalletModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Account info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p
              style={{
                fontFamily: "monospace, sans-serif",
                fontSize: "24px",
              }}
            >
              {maskedAccount()}
            </p>
            <p>
              <button
                className="btn btn-sm btn-secondary text-white"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Copy address"
                onClick={copyAddress}
              >
                <i className="bi-clipboard" aria-hidden="true"></i> Copy Address
              </button>{" "}
              <button
                className="btn btn-sm btn-secondary text-white"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View on Explorer"
                onClick={viewOnExplorer}
              >
                <i className="bi-box-arrow-up-right" aria-hidden="true"></i>{" "}
                View on Explorer
              </button>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <p>
              <button
                className="btn btn-primary"
                style={{
                  padding: "10px 30px",
                  border: "1px solid rgb(230, 76, 64)",
                  borderRadius: "4px",
                  fontSize: "18px",
                  letterSpacing: "2px",
                  backgroundColor: "rgb(15, 26, 31)",
                }}
                onClick={(e) => {
                  setIsConnected(false);
                  setIsDisconnected(true);
                  setProvider(null);
                  setAccount(null);
                  setChainId(null);
                  setShowWalletModal(false);
                }}
              >
                Disconnect
              </button>
            </p>
          </Modal.Footer>
        </Modal>
      ) : null}
      <Modal
        id="invalidChainModal"
        show={isInvalidChain && isConnected}
        onHide={() => setShowWalletModal(false)}
        keyboard={false}
        enforceFocus={true}
      >
        <Modal.Header>
          <Modal.Title>Unsupported Network</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            <p>Unsupported network selected. Please, be sure to select <strong>{networkConfigs[defaultNetwork].chainName}</strong>.</p>
          </Alert>
        </Modal.Body>
      </Modal>
    </>
  );
}
