import React from "react";
import { Navbar, Container } from "react-bootstrap";
import ConnectButton from "../ConnectButton/ConnectButton";
import Logo from "./../../assets/CURE-full-logo.png";

export default function Header() {
  return (
    <header>
      <Navbar bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand>
            <img
              alt=""
              src={Logo}
              className="d-inline-block align-top"
              style={{ maxHeight: "50px", width: "100%"}}
            />
          </Navbar.Brand>
          <div style={{ display: "inline-block" }} className="ms-auto">
            <ConnectButton />
          </div>
        </Container>
      </Navbar>
    </header>
  );
}
