import React, { Component } from "react";

export default class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdown: "",
      date: props.date,
    };
  }

  componentDidMount() {
    if (this.state.date > Date.now()) this.doCountDown();
    else if (this.state.date === 0) this.setState({ countdown: "TBA" });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.date !== state.date) {
      return {
        date: props.date,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  doCountDown = async () => {
    this.countdownInternval = setInterval(() => {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = this.state.date - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.setState({
        countdown: days + "d " + hours + "h " + minutes + "m " + seconds + "s ",
      });

      // count down finished
      if (distance < 0) {
        clearInterval(this.countdownInternval);
        this.setState({ countdown: "" });
        if (this.props.callback !== undefined) this.props.callback();
      }
    }, 1000);
  };

  render() {
    return <>{this.state.countdown}</>;
  }
}
