import Web3 from "web3";

const stakingABI = require("./CUREStaking.json");
const tokenABI = require("./ERC20.json");

export const networkConfigs = {
  "0x61": {
    chainId: "0x61",
    chainName: "BSC Testnet",
    nativeCurrency: {
      symbol: "BNB",
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
  "0x38": {
    chainId: "0x38",
    chainName: "BSC Mainnet",
    nativeCurrency: {
      symbol: "BNB",
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
};

export const contracts = {
  "0x61": {
    // testnet addresses
    token: "0x959a0fe5E86F16b20476C6e31cA5f1ba15DCcA1B",
    staking: "0xE742a75002bb59f93a19a3dC69FE85Fbf28ff8a6",
    distributor: "0x01bF8C68b829361B520ebA98f04826A15696dD71",
  },
  "0x38": {
    // mainnet addresses
    token: "0x76aECB353AbF596BD61EE6BDb07d70787DeC4FD6",
    staking: "0x43900bB6a6D990f7577BDE386c6D241857b3C789",
    distributor: "0xAe1D294Aa8c5ab6Ae76fd209E98B969F9F87A3F2",
  },
};

export const defaultNetwork = "0x38";

export const StakingLib = (() => {
  const MAX_UINT =
    "115792089237316195423570985008687907853269984665640564039457584007913129639935";
  return {
    allowance: async (account, tokenAddress) => {
      const web3 = new Web3(networkConfigs[defaultNetwork].rpcUrls[0]);
      const token = new web3.eth.Contract(tokenABI, tokenAddress);

      return token.methods
        .allowance(account, contracts[defaultNetwork].staking)
        .call();
    },
    approve: async (
      provider,
      account,
      tokenAddress,
      onTransactionHash,
      onConfirmation,
      onError
    ) => {
      const web3 = new Web3(provider);
      const token = new web3.eth.Contract(tokenABI, tokenAddress);

      return token.methods
        .approve(contracts[defaultNetwork].staking, web3.utils.toBN(MAX_UINT))
        .send({ from: account })
        .on("transactionHash", (hash) => onTransactionHash(hash))
        .on("confirmation", (confirmationNumber, receipt) =>
          onConfirmation(confirmationNumber, receipt)
        )
        .on("error", (error, receipt) => onError(error, receipt));
    },
    deposit: async (
      provider,
      account,
      pId,
      amount,
      tokenAddress,
      onTransactionHash,
      onConfirmation,
      onError
    ) => {
      const web3 = new Web3(provider);
      const token = new new Web3(
        networkConfigs[defaultNetwork].rpcUrls[0]
      ).eth.Contract(tokenABI, tokenAddress);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );
      const tokenDecimals = await token.methods.decimals().call();
      const weiAmount = amount + "0".repeat(tokenDecimals);

      const bnAmount = web3.utils.toBN(weiAmount);

      return staking.methods
        .deposit(pId, bnAmount)
        .send({ from: account })
        .on("transactionHash", (hash) => onTransactionHash(hash))
        .on("confirmation", (confirmationNumber, receipt) =>
          onConfirmation(confirmationNumber, receipt)
        )
        .on("error", (error, receipt) => onError(error, receipt));
    },
    withdraw: async (
      provider,
      account,
      pId,
      amount,
      tokenAddress,
      onTransactionHash,
      onConfirmation,
      onError
    ) => {
      const web3 = new Web3(provider);
      const token = new new Web3(
        networkConfigs[defaultNetwork].rpcUrls[0]
      ).eth.Contract(tokenABI, tokenAddress);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );
      const tokenDecimals = await token.methods.decimals().call();
      const weiAmount = amount + "0".repeat(tokenDecimals);

      const bnAmount = web3.utils.toBN(weiAmount);

      return staking.methods
        .withdraw(pId, bnAmount)
        .send({ from: account })
        .on("transactionHash", (hash) => onTransactionHash(hash))
        .on("confirmation", (confirmationNumber, receipt) =>
          onConfirmation(confirmationNumber, receipt)
        )
        .on("error", (error, receipt) => onError(error, receipt));
    },
    withdrawAll: async (
      provider,
      account,
      pId,
      onTransactionHash,
      onConfirmation,
      onError
    ) => {
      const web3 = new Web3(provider);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      return staking.methods
        .withdrawAll(pId)
        .send({ from: account })
        .on("transactionHash", (hash) => onTransactionHash(hash))
        .on("confirmation", (confirmationNumber, receipt) =>
          onConfirmation(confirmationNumber, receipt)
        )
        .on("error", (error, receipt) => onError(error, receipt));
    },
    harvest: async (
      provider,
      account,
      pId,
      onTransactionHash,
      onConfirmation,
      onError
    ) => {
      const web3 = new Web3(provider);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      return staking.methods
        .harvest(pId)
        .send({ from: account })
        .on("transactionHash", (hash) => onTransactionHash(hash))
        .on("confirmation", (confirmationNumber, receipt) =>
          onConfirmation(confirmationNumber, receipt)
        )
        .on("error", (error, receipt) => onError(error, receipt));
    },
    harvestAll: async (
      provider,
      account,
      onTransactionHash,
      onConfirmation,
      onError
    ) => {
      const web3 = new Web3(provider);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      return staking.methods
        .harvestAll()
        .send({ from: account })
        .on("transactionHash", (hash) => onTransactionHash(hash))
        .on("confirmation", (confirmationNumber, receipt) =>
          onConfirmation(confirmationNumber, receipt)
        )
        .on("error", (error, receipt) => onError(error, receipt));
    },
    emergencyWithdraw: async (
      provider,
      account,
      pId,
      onTransactionHash,
      onConfirmation,
      onError
    ) => {
      const web3 = new Web3(provider);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      return staking.methods
        .emergencyWithdraw(pId)
        .send({ from: account })
        .on("transactionHash", (hash) => onTransactionHash(hash))
        .on("confirmation", (confirmationNumber, receipt) =>
          onConfirmation(confirmationNumber, receipt)
        )
        .on("error", (error, receipt) => onError(error, receipt));
    },
    getPoolsLength: async () => {
      const web3 = new Web3(networkConfigs[defaultNetwork].rpcUrls[0]);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      return staking.methods.getPoolsLength().call();
    },
    getPoolInfo: async (pId) => {
      const web3 = new Web3(networkConfigs[defaultNetwork].rpcUrls[0]);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );
      
      return staking.methods.poolInfo(pId).call();
    },
    getPoolAmount: async (pId) => {
      const web3 = new Web3(networkConfigs[defaultNetwork].rpcUrls[0]);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      return staking.methods.poolAmount(pId).call();
    },
    getUserInfo: async (pId, account) => {
      const web3 = new Web3(networkConfigs[defaultNetwork].rpcUrls[0]);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      return staking.methods.userInfo(pId, account).call();
    },
    getUserBalance: async (account) => {
      const web3 = new Web3(networkConfigs[defaultNetwork].rpcUrls[0]);
      const token = new web3.eth.Contract(
        tokenABI,
        contracts[defaultNetwork].token
      );

      return token.methods.balanceOf(account).call();
    },
    getPendingRewards: async (pId, account) => {
      const web3 = new Web3(networkConfigs[defaultNetwork].rpcUrls[0]);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      return staking.methods.pendingRewards(pId, account).call();
    },
    getLockExpirationTime: async (pId, account) => {
      const web3 = new Web3(networkConfigs[defaultNetwork].rpcUrls[0]);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      return staking.methods.getLockExpirationTime(pId, account).call();
    },
    onHarvest: async (provider, pId, account, callback) => {
      const web3 = new Web3(provider);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      const filter = {
        pId: pId,
        to: account.toLowerCase(),
      };

      return staking.events.Harvest(
        {
          filter: filter,
          fromBlock: "latest",
        },
        callback
      );
    },
    onPoolDeposit: async (provider, pId, callback) => {
      const web3 = new Web3(provider);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      const filter = {
        pId: pId,
      };

      return staking.events.Deposit(
        {
          filter: filter,
          fromBlock: "latest",
        },
        callback
      );
    },
    onPoolWithdraw: async (provider, pId, callback) => {
      const web3 = new Web3(provider);
      const staking = new web3.eth.Contract(
        stakingABI,
        contracts[defaultNetwork].staking
      );

      const filter = {
        pId: pId,
      };

      return staking.events.Withdraw(
        {
          filter: filter,
          fromBlock: "latest",
        },
        callback
      );
    },
    onUserApproval: async (provider, account, callback) => {
      const web3 = new Web3(provider);
      const token = new web3.eth.Contract(
        tokenABI,
        contracts[defaultNetwork].token
      );

      const filter = {
        owner: account,
        spender: contracts[defaultNetwork].staking,
      };

      return token.events.Approval(
        {
          filter: filter,
          fromBlock: "latest",
        },
        callback
      );
    },
  };
})();
