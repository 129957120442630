import { Container } from "react-bootstrap";

export default function Footer() {
  return (
    <footer>
      <Container>
        <p className="text-white">
          Copyright © 2021 The Beckley Foundation. All Rights Reserved.
        </p>
      </Container>
    </footer>
  );
}
