import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import AppContext from "./AppContext";
import * as React from "react";
import { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PageContent from "./components/PageContent/PageContent";

function App() {
  const [isConnected, setIsConnected] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isApproving, setIsApproving] = useState(false);

  useEffect(() => {
    setIsDisconnected(JSON.parse(localStorage.getItem("isDisconnected")));
  }, []);

  const _setIsConnected = (connected) => {
    setIsConnected(connected);
  };

  const _setIsDisconnected = (disconnected) => {
    localStorage.setItem("isDisconnected", disconnected.toString());
    setIsDisconnected(disconnected);
  };

  const _setAccount = (account) => {
    setAccount(account);
  };

  const _setChainId = (chainId) => {
    setChainId(chainId);
  };

  const _setProvider = (provider) => {
    setProvider(provider);
  };

  const _setIsApproving = (approving) => {
    setIsApproving(approving);
  }

  return (
    <AppContext.Provider
      value={{
        isConnected,
        setIsConnected: _setIsConnected,
        isDisconnected,
        setIsDisconnected: _setIsDisconnected,
        account,
        setAccount: _setAccount,
        chainId,
        setChainId: _setChainId,
        provider,
        setProvider: _setProvider,
        isApproving,
        setIsApproving: _setIsApproving,
      }}
    >
      <Header />
      <PageContent />
      <Footer />
    </AppContext.Provider>
  );
}

export default App;
