import { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Col,
  Row,
  Button,
  Spinner,
  Form,
  FormGroup,
} from "react-bootstrap";
import Web3 from "web3";
import AppContext from "../../AppContext";
import {
  defaultNetwork,
  networkConfigs,
  StakingLib,
} from "../../lib/StakingLib";
import Countdown from "../Countdown/Countdown";
import toastr from "toastr";

export default function PoolInfo(props) {
  const [loadingPoolInfo, setLoadingPoolInfo] = useState(true);
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [poolAmount, setPoolAmount] = useState("0");
  const [stake, setStake] = useState("0");
  const [isLocked, setIsLocked] = useState(false);
  const [expirationTime, setExpirationTime] = useState(0);
  const [depositAmount, setDepositAmount] = useState("0");
  const [withdrawAmount, setWithdrawAmount] = useState("0");
  const [allowance, setAllowance] = useState("0");
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [isDepositing, setIsDepositing] = useState(false);
  const [isHarvesting, setIsHarvesting] = useState(false);
  const [listeningToPoolDeposits, setListeningToPoolDeposits] = useState(false);
  const [listeningToPoolWithdraw, setListeningToPoolWithdraw] = useState(false);
  const [listeningToPoolHarvest, setListeningToPoolHarvest] = useState(false);
  const [lockTimeout, setLockTimeout] = useState(null);
  const [token, setToken] = useState("");

  // const [balance, setBalance] = useState("0");

  const {
    isConnected,
    isDisconnected,
    account,
    provider,
    chainId,
    isApproving,
    setIsApproving,
  } = useContext(AppContext);

  useEffect(() => {
    doLoadingPoolInfo();
  }, []);

  useEffect(() => {
    loadUserInfo();
    getAllowance();
  }, [isConnected, account]);

  // useEffect(() => {
  //   if (isConnected && account != null && provider != null) {
  //     if (!listeningToPoolDeposits) listenToPoolDeposits();
  //     if (!listeningToPoolWithdraw) listenToPoolWithdraw();
  //     if (!listeningToPoolHarvest) listenToPoolHarvest();
  //   }
  // }, [isConnected, account, provider]);

  const getAllowance = async () => {
    if (isConnected && account !== null && token != "") {
      setAllowance(await StakingLib.allowance(account, token));
      StakingLib.onUserApproval(provider, account, async (error, event) => {
        setAllowance(await StakingLib.allowance(account, token));
      });
    }
  };

  const loadUserInfo = async () => {
    setLoadingUserInfo(true);
    if (isConnected && account != null) {
      getUserStake();
      getLockExpirationTime();
    } else {
      setLoadingUserInfo(false);
    }
  };

  const getLockExpirationTime = async () => {
    try {
      const expirationTime = await StakingLib.getLockExpirationTime(
        props.pId,
        account
      );
      const fixedExpirationTime = expirationTime * 1000;
      setExpirationTime(fixedExpirationTime);
      setIsLocked(Date.now() < fixedExpirationTime);
      // if (fixedExpirationTime > Date.now()) {
      //   if (lockTimeout !== null) clearTimeout(lockTimeout);
      //   setLockTimeout(
      //     setTimeout(() => loadUserInfo(), fixedExpirationTime - Date.now())
      //   );
      // } else {
      //   setLockTimeout(null);
      // }
    } catch {
      setTimeout(() => getLockExpirationTime(), 10000);
    }
  };

  const getUserStake = async () => {
    try {
      const userInfo = await StakingLib.getUserInfo(props.pId, account);
      setStake(userInfo.amount);
      setLoadingUserInfo(false);
    } catch {
      // retry within 5 seconds
      setTimeout(() => getUserStake(), 5000);
    }
  };

  const doLoadingPoolInfo = async () => {
    setLoadingPoolInfo(true);
    await loadPoolInfo();
    // refresh info every 1 minute
    setTimeout(() => doLoadingPoolInfo(), 60000);
  };

  const loadPoolInfo = async () => {
    // try {
    if (props.isComingSoon) {
      setToken("");
      setPoolAmount("0");
      setLoadingPoolInfo(false);
    } else {
      StakingLib.getPoolInfo(props.pId)
        .then((poolInfo) => {
          setToken(poolInfo.token);
          getPoolAmount();
        })
        .catch((error) => {
          setTimeout(() => loadPoolInfo(), 3000);
        });
    }
    // } catch {
    //   // retry within 3 seconds
    //   setTimeout(() => loadPoolInfo(), 3000);
    // }
  };

  const getPoolAmount = async () => {
    StakingLib.getPoolAmount(props.pId)
      .then((amount) => {
        setPoolAmount(amount);
        setLoadingPoolInfo(false);
      })
      .catch((error) => {
        setTimeout(() => getPoolAmount(), 3000);
      });
  };

  const spinner = () => (
    <Spinner animation="border" role="status" variant="warning">
      <span className="visually-hidden">Loading pool info...</span>
    </Spinner>
  );
  const smallSpinner = () => (
    <Spinner animation="border" role="status" variant="warning" size="sm">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );

  const onApproveClick = async (e) => {
    const notificationTitle = "Approve";
    setIsApproving(true);
    await StakingLib.approve(
      provider,
      account,
      token,
      (hash) => notifyTxSubmitted(notificationTitle, hash),
      (confirmationNumber, receipt) => {
        if (confirmationNumber === 1) {
          notifyTxCompleted(notificationTitle, receipt.transactionHash);
          getAllowance();
          setIsApproving(false);
        }
      },
      (error, receipt) => {
        if (error.code !== 4001) {
          // 4001 = User denied transaction signature.
          if (receipt === undefined)
            notifyError(error.message, notificationTitle);
          else notifyTxFailed(notificationTitle, receipt.transactionHash);
        }
        setIsApproving(false);
      }
    );
  };
  const onDepositClick = async (e) => {
    const notificationTitle = "Deposit";
    setIsDepositing(true);
    const amount = depositAmount;
    setDepositAmount("0");
    await StakingLib.deposit(
      provider,
      account,
      props.pId,
      amount,
      token,
      (hash) => notifyTxSubmitted(notificationTitle, hash),
      (confirmationNumber, receipt) => {
        if (confirmationNumber === 0) {
          notifyTxCompleted(notificationTitle, receipt.transactionHash);
          loadPoolInfo();
          loadUserInfo();
          setIsDepositing(false);
        }
      },
      (error, receipt) => {
        if (error.code !== 4001) {
          // 4001 = User denied transaction signature.
          if (receipt === undefined)
            notifyError(error.message, notificationTitle);
          else notifyTxFailed(notificationTitle, receipt.transactionHash);
        }
        setIsDepositing(false);
      }
    );
  };
  const onHarvestClick = async (e) => {
    const notificationTitle = "Harvest";
    setIsHarvesting(true);
    await StakingLib.harvest(
      provider,
      account,
      props.pId,
      (hash) => notifyTxSubmitted(notificationTitle, hash),
      (confirmationNumber, receipt) => {
        if (confirmationNumber === 0) {
          notifyTxCompleted(notificationTitle, receipt.transactionHash);
          loadPoolInfo();
          loadUserInfo();
          setIsHarvesting(false);
        }
      },
      (error, receipt) => {
        if (error.code !== 4001) {
          // 4001 = User denied transaction signature.
          if (receipt === undefined)
            notifyError(error.message, notificationTitle);
          else notifyTxFailed(notificationTitle, receipt.transactionHash);
        }
        setIsHarvesting(false);
      }
    );
  };
  const onWithdrawClick = async (e) => {
    const notificationTitle = "Withdraw";
    setIsWithdrawing(true);
    const amount = withdrawAmount;
    setWithdrawAmount("0");
    await StakingLib.withdraw(
      provider,
      account,
      props.pId,
      amount,
      token,
      (hash) => notifyTxSubmitted(notificationTitle, hash),
      (confirmationNumber, receipt) => {
        if (confirmationNumber === 0) {
          notifyTxCompleted(notificationTitle, receipt.transactionHash);
          loadPoolInfo();
          loadUserInfo();
          setIsWithdrawing(false);
        }
      },
      (error, receipt) => {
        if (error.code !== 4001) {
          // 4001 = User denied transaction signature.
          if (receipt === undefined)
            notifyError(error.message, notificationTitle);
          else notifyTxFailed(notificationTitle, receipt.transactionHash);
        }
        setIsWithdrawing(false);
      }
    );
  };
  const onWithdrawAllClick = async (e) => {
    const notificationTitle = "Withdraw All";
    setIsWithdrawing(true);
    await StakingLib.withdrawAll(
      provider,
      account,
      props.pId,
      (hash) => notifyTxSubmitted(notificationTitle, hash),
      (confirmationNumber, receipt) => {
        if (confirmationNumber === 0) {
          notifyTxCompleted(notificationTitle, receipt.transactionHash);
          loadPoolInfo();
          loadUserInfo();
          setIsWithdrawing(false);
        }
      },
      (error, receipt) => {
        if (error.code !== 4001) {
          // 4001 = User denied transaction signature.
          if (receipt === undefined)
            notifyError(error.message, notificationTitle);
          else notifyTxFailed(notificationTitle, receipt.transactionHash);
        }
        setIsWithdrawing(false);
      }
    );
  };

  const onlyNumbers = (e, setValueCb) => {
    var regExp = new RegExp(/^\d*\.?\d*$/);
    if (regExp.test(e.target.value)) {
      setValueCb(e.target.value);
    } else {
      e.preventDefault();
    }
  };

  const notifyTxSubmitted = (title, txHash) => {
    toastr.info(
      `<a target="_blank" href="${
        networkConfigs[defaultNetwork].blockExplorerUrls[0] + "tx/" + txHash
      }"><i className="fa fa-external-link me-2" aria-hidden></i>Transaction submitted.</a>`,
      title,
      { timeOut: 10000 }
    );
  };
  const notifyTxCompleted = (title, txHash) => {
    toastr.success(
      `<a target="_blank" href="${
        networkConfigs[defaultNetwork].blockExplorerUrls[0] + "tx/" + txHash
      }"><i className="fa fa-external-link me-2" aria-hidden></i>Transaction completed.</a>`,
      title,
      { timeOut: 10000 }
    );
  };
  const notifyTxFailed = (title, txHash) => {
    toastr.error(
      `<a target="_blank" href="${
        networkConfigs[defaultNetwork].blockExplorerUrls[0] + "tx/" + txHash
      }"><i className="fa fa-external-link me-2" aria-hidden></i>Transaction failed.</a>`,
      title,
      { timeOut: 10000 }
    );
  };
  const notifyError = (text, title) => {
    toastr.error(text, title);
  };

  const showLock = () =>
    isLocked ? <i className="bi-lock-fill" title="Locked tokens"></i> : null;

  const onDepositOrWithdraw = (event) => {
    loadPoolInfo();
    const from = Web3.utils.toChecksumAddress(
      "0x" + event.raw.topics[2].substr(26)
    );
    if (from === Web3.utils.toChecksumAddress(account)) loadUserInfo();
  };

  // const listenToPoolDeposits = async () => {
  //   setListeningToPoolDeposits(true);
  //   StakingLib.onPoolDeposit(provider, props.pId, (error, event) => {
  //     if (error === null) {
  //       onDepositOrWithdraw(event);
  //     }
  //   });
  // };

  // const listenToPoolWithdraw = async () => {
  //   setListeningToPoolWithdraw(true);
  //   StakingLib.onPoolWithdraw(provider, props.pId, (error, event) => {
  //     if (error === null) {
  //       onDepositOrWithdraw(event);
  //     }
  //   });
  // };

  // const listenToPoolHarvest = async () => {
  //   setListeningToPoolHarvest(true);
  //   StakingLib.onHarvest(provider, props.pId, account, (error, event) => {
  //     if (error === null) {
  //       onDepositOrWithdraw(event);
  //     }
  //   });
  // };

  const onCoundownComplete = () => {
    loadUserInfo();
  };

  return (
    <Accordion className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Row className="w-100" style={{ alignItems: "center" }}>
            <Col className="mt-2  mb-2" sm="12" md="3">
              <strong
                className="h4"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={props.logo} width="48" alt="CURE token logo" />
                &nbsp;{props.symbol}
              </strong>
            </Col>
            <Col className="mt-2 mb-2" xs="6" md="3">
              <p>Total Staked</p>
              {loadingPoolInfo ? (
                <div>{spinner()}</div>
              ) : (
                <>
                  <strong
                    className="h4"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {parseFloat(
                      Web3.utils.fromWei(poolAmount)
                    ).toLocaleString()}
                  </strong>
                </>
              )}
            </Col>
            <Col className="mt-2 mb-2" xs="6" md="3">
              <p>Your Stake</p>
              {loadingUserInfo ? (
                <div>{spinner()}</div>
              ) : (
                <>
                  <strong
                    className="h4"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {isConnected
                      ? parseFloat(Web3.utils.fromWei(stake)).toLocaleString()
                      : "-"}
                    {isConnected ? showLock() : null}
                  </strong>
                </>
              )}
            </Col>
            <Col className="mt-2 mb-2" md="3">
              <p>Locked - {props.lock}</p>
              <span className="h4">{props.rate}</span>
            </Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body className="text-black">
          <Row>
            <Col md="4" className="mt-2 mb-2">
              <Form>
                <FormGroup controlId="depositAmount" className="mb-2">
                  <Form.Label>
                    <strong>Balance:</strong>{" "}
                    {isConnected
                      ? parseFloat(
                          Web3.utils.fromWei(props.amountOnWallet, "nano")
                        ).toLocaleString()
                      : "0"}{" "}
                    {props.symbol}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={depositAmount}
                    onChange={(e) => onlyNumbers(e, setDepositAmount)}
                    disabled={Web3.utils.toBN(allowance).eqn(0)}
                  ></Form.Control>
                </FormGroup>
                
              </Form>
            </Col>
            <Col md="4" className="mt-2 mb-2">
              <Form>
                <FormGroup controlId="depositAmount" className="mb-2">
                  <Form.Label>
                    <strong>Withdraw:</strong>{" "}
                    {parseFloat(Web3.utils.fromWei(stake)).toLocaleString()}{" "}
                    {props.symbol} {isConnected ? showLock() : null}{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    disabled={
                      isLocked ||
                      stake === undefined ||
                      Web3.utils.toBN(stake).eqn(0)
                    }
                    value={withdrawAmount}
                    onChange={(e) => onlyNumbers(e, setWithdrawAmount)}
                  ></Form.Control>
                </FormGroup>
                <div className="text-center">
                  {isConnected &&
                  account != null &&
                  parseFloat(stake) > 0 &&
                  isLocked &&
                  expirationTime > Date.now() ? (
                    <>
                      {showLock()}{" "}
                      <Countdown
                        date={expirationTime}
                        callback={onCoundownComplete}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        variant="primary"
                        disabled={isLocked}
                        className="mb-2"
                        onClick={onWithdrawClick}
                        disabled={
                          stake === undefined ||
                          Web3.utils.toBN(stake).eqn(0) ||
                          Web3.utils.toBN(withdrawAmount).eqn(0) ||
                          isWithdrawing ||
                          isDisconnected
                        }
                      >
                        {isWithdrawing ? <>{smallSpinner()} </> : null}
                        Withdraw {showLock()}
                      </Button>
                      <Button
                        variant="primary"
                        className="ms-2 mb-2"
                        disabled={isLocked}
                        onClick={onWithdrawAllClick}
                        disabled={
                          stake === undefined ||
                          Web3.utils.toBN(stake).eqn(0) ||
                          isWithdrawing ||
                          isDisconnected
                        }
                      >
                        {isWithdrawing ? <>{smallSpinner()} </> : null}
                        Withdraw All {showLock()}
                      </Button>
                    </>
                  )}
                </div>
              </Form>
            </Col>
            <Col md="4" className="mt-2 mb-2 text-center">
              <p className="h4 mb-2">Rewards</p>
              <p
                className="h4 mb-2"
                title={
                  props.userRewards !== undefined
                    ? Web3.utils.fromWei(props.userRewards)
                    : ""
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={props.rewardLogo} alt="token logo" width="32" />
                &nbsp;
                {props.userRewards !== undefined && isConnected
                  ? parseFloat(Web3.utils.fromWei(props.userRewards))
                      .toFixed(3)
                      .toLocaleString()
                  : "0.000"}
              </p>
              {isConnected &&
              account != null &&
              parseFloat(stake) > 0 &&
              isLocked &&
              expirationTime > Date.now() ? (
                <>
                  {showLock()} <Countdown date={expirationTime} />
                </>
              ) : (
                <Button
                  variant="primary"
                  onClick={onHarvestClick}
                  disabled={
                    props.userRewards === undefined ||
                    Web3.utils.toBN(props.userRewards).eqn(0) ||
                    isHarvesting ||
                    isDisconnected
                  }
                >
                  {isHarvesting ? <>{smallSpinner()} </> : null}
                  Harvest
                </Button>
              )}
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
